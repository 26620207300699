import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import Navbar from './components/Navbar';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { settings } from './settings.js'
import Jumbotron from './components/Jumbotron';
import Footer from './components/Footer';
import Features from './components/Features';
import Placeholder from './components/Placeholder';

const navbar = ReactDOM.createRoot(document.getElementById('navbar'));
navbar.render(
  <React.StrictMode>
    <Navbar navitems={settings.navitems} heading={settings.title} />
  </React.StrictMode>
);

const main = ReactDOM.createRoot(document.getElementById('main'));
main.render(
  <React.StrictMode>
    <Jumbotron
      churchName={settings.title}
      logo={settings.logo}
      welcome={settings.welcome}
      buttonOneLabel={settings.jumbotronButtonOneLabel}
      buttonOneURL={settings.jumbotronButtonOneURL}
      buttonTwoLabel={settings.jumbotronButtonTwoLabel}
      buttonTwoURL={settings.jumbotronButtonTwoURL}
    />
    <Features />
    <Placeholder />
  </React.StrictMode>
);

const footer = ReactDOM.createRoot(document.getElementById('footer'));
footer.render(
  <React.StrictMode>
    <Footer />
  </React.StrictMode>
);

reportWebVitals();
