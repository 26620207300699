
export const settings = {
    title: process.env.REACT_APP_TITLE,
    logo: process.env.REACT_APP_LOGO,
    navitems: [
        'Home',
        'About',
        'Ministries',
        'Connect',
        'Give'
      ],
    welcome: process.env.REACT_APP_WELCOME,
    jumbotronButtonOneLabel: process.env.REACT_APP_JUMBOTRON_BUTTON_ONE_LABEL,
    jumbotronButtonOneURL: process.env.REACT_APP_JUMBOTRON_BUTTON_ONE_URL,
    jumbotronButtonTwoLabel: process.env.REACT_APP_JUMBOTRON_BUTTON_TWO_LABEL,
    jumbotronButtonTwoURL: process.env.REACT_APP_JUMBOTRON_BUTTON_TWO_URL,
    email: process.env.REACT_APP_EMAIL,
    phone: process.env.REACT_APP_PHONE,
    address: process.env.REACT_APP_ADDRESS,
    statement: process.env.REACT_APP_STATEMENT,
    statementExpanded: process.env.REACT_APP_STATEMENT_EXPANDED,
    facebook: process.env.REACT_APP_FACEBOOK,
    twitter: process.env.REACT_APP_TWITTER,
    instagram: process.env.REACT_APP_INSTAGRAM,
    youtube: process.env.REACT_APP_YOUTUBE,
};
