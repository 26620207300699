import { useState } from "react";

function Navbar({heading, navitems}) {

  const [selectedIndex, setSelectedIndex] = useState(-1)

  return (
    <nav className="navbar navbar-expand-lg bg-body-tertiary">
      <div className="container-fluid">
        <a className="navbar-brand" href="/">{heading}</a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <ul className="navbar-nav">
            {navitems.map((item, index) => (
              <li 
                key={item} 
                className={selectedIndex === index ? "nav-item active" : "nav-item"}
              >
                  <a
                    className="nav-link"
                    href={'#/'+item}
                    onClick={() => setSelectedIndex(index) }
                  >
                    {item}
                  </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
