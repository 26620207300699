function Jumbotron({churchName, logo, welcome, buttonOneLabel, buttonOneURL, buttonTwoLabel, buttonTwoURL}) {

    return (
      <div className="p-5 text-center bg-body-tertiary rounded-3">
        <img src={logo} width="50" height="50" alt="logo" />
        <h1 className="text-body-emphasis my-4">Welcome to {churchName}</h1>
        <p className="col-lg-8 mx-auto fs-5 text-muted">
          {welcome}
        </p>
        <div className="d-inline-flex gap-2 my-4">
          <button
            className="d-inline-flex align-items-center btn btn-primary btn-lg px-4 rounded-pill"
            type="button"
            onClick={() => window.location=buttonOneURL}
          >
            {buttonOneLabel}
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-arrow-right-short ms-2" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
            </svg>
          </button>
          <button
            className="btn btn-outline-secondary btn-lg px-4 rounded-pill"
            type="button"
            onClick={() => window.location=buttonTwoURL}
          >
            {buttonTwoLabel}
          </button>
        </div>
      </div>

);
}

export default Jumbotron;
